



























import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import {
  RateVideoAction,
  Seminar,
  SeminarElement,
  SimpleUser,
  Submission,
} from "@/core/models";
import api from "@/core/utils/api";
import VideoSubmission from "./VideoSubmission.vue";
import ElementControls from "./ElementControls.vue";

@Component({ components: { VideoSubmission, ElementControls } })
export default class WatchFeedbackElement extends Vue {
  @Prop({ default: () => ({} as SeminarElement) }) element!: SeminarElement;

  @Getter("profile/id") userId!: string;

  @Getter("seminars/users") users!: SimpleUser[];
  @Getter("seminars/selected") seminar?: Seminar;

  @Action("seminars/next") next!: () => void;
  @Action("seminars/addSubmission") addSubmission!: (s: Submission) => void;
  @Action("seminars/rateFeedbackVideo") rateFeedbackVideo!: (d: any) => void;

  user(id: string) {
    const one = this.users.find(x => x.id === id);
    if (!one) return "Seminar owner";
    return `${one.username} (${one.email})`;
  }

  get skipped() {
    return this.seminar?.submissions?.find(
      x => x.skipped && x.elementId === this.element.id,
    );
  }

  videos: Submission[] = [];
  loading = false;
  async getVideos() {
    if (!this.seminar) return;
    this.loading = true;
    try {
      this.videos = (await api.get(
        `/api/Elements/Feedback/${this.seminar.id}`,
      )) as Submission[];
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async submit() {
    if (!this.videos.length) throw new Error("no_video");
    const submission = (await api.post("/api/Submissions/WatchFeedback", {
      elementId: this.element.id,
      seminarId: this.element.seminarId,
    })) as Submission;
    this.addSubmission(submission);
    this.next();
  }

  rateFeedback(d: RateVideoAction) {
    if (!this.seminar) return;
    this.rateFeedbackVideo({ ...d, seminarId: this.seminar.id });
  }

  @Watch("seminar", { immediate: true })
  seminarUpdated() {
    this.getVideos();
  }
}
