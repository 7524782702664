import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mt-12 mb-4"},[_c(VBtn,{attrs:{"elevation":"0","disabled":_vm.loading},on:{"click":_vm.prev}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.$t("seminars.prev")))])],1),_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0","color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.callNext}},[_c('span',[_vm._v(_vm._s(_vm.isLast ? _vm.$t("seminars.finish") : _vm.$t("seminars.next")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.isLast ? "mdi-check" : "mdi-chevron-right")+" ")])],1)],1),(_vm.isLast)?_c(VDialog,{attrs:{"max-width":"400px"},model:{value:(_vm.endDialog),callback:function ($$v) {_vm.endDialog=$$v},expression:"endDialog"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("seminars.done")))]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("seminars.doneText"))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0"},on:{"click":_vm.toDash}},[_c('span',[_vm._v(_vm._s(_vm.$t("seminars.back")))])]),_c(VBtn,{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.endDialog = false}}},[_c('span',[_vm._v("OK")])])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }