












































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Seminar, SeminarElement } from "@/core/models";
import { elementStatus } from "@/core/utils/seminars";
import i18n from "@/core/plugins/i18n";

const emptySubmissionElements = new Map([
  ["read_text", "/api/Submissions/ReadText"],
  ["watch_video", "/api/Submissions/WatchVideo"],
]);

@Component
export default class ElementControls extends Vue {
  @Prop({ default: () => false }) isLast!: boolean;
  @Prop({ default: () => {} }) submit!: () => Promise<void>;

  @Getter("seminars/selected") seminar?: Seminar;
  @Getter("seminars/element") element!: SeminarElement;

  @Getter("profile/id") userId!: string;

  @Action("seminars/next") next!: () => void;
  @Action("seminars/prev") prev!: () => void;
  @Action("seminars/skip") skip!: (el: SeminarElement) => void;
  @Action("seminars/submitEmpty") submitEmpty!: (el: SeminarElement) => void;

  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  loading = false;
  endDialog = false;

  get isComplete() {
    return elementStatus(this.element, this.seminar) === "completed";
  }

  get subs() {
    return (
      this.seminar?.submissions?.filter(x => x.elementId === this.element.id) ||
      []
    );
  }

  async callNext() {
    // no element
    if (!this.element) return this.displaySnackbar("Element not found");

    // element completed
    if (this.isComplete) {
      if (this.isLast) this.endDialog = true;
      return this.next();
    }

    // specific to vr_training
    if (this.element.type === "vr_training") {
      const reqScore = this.element.requiredScore;
      const bestScore =
        this.subs
          .map(x => (x as any).score as number)
          .sort((a, b) => b - a)[0] || 0;
      if (bestScore < reqScore)
        return this.displaySnackbar(
          "You need to reach the required score in order to proceed",
        );
      else return this.next();
    }

    // specific to give_feedback
    if (this.element.type === "give_feedback") {
      if (this.subs.some(x => !x.skipped)) return this.next();

      const msg = i18n.t("seminars.submissions.giveFeedbackInfo").toString();
      return this.displaySnackbar(msg);
    }

    // specific to video_presentations
    if (this.element.type === "video_presentation") {
      const subs = this.subs.filter(x => !x.skipped);
      const numReq = this.element.requiredSubmissions || 1;
      const numSub = subs?.length || 0;
      const numReqShared = this.element.rateableSubmissions
        ? this.element.requiredNumShared || 0
        : -1;
      const numShared = subs?.filter(x => x.isShared).length || 0;

      // requirements not met
      if (numReq > numSub || numReqShared > numShared) {
        const submissions = numReq - numSub;
        const sharedSubmissions =
          numReqShared === -1 ? 0 : numReqShared - numShared;
        const m = this.$t("seminars.video_presentation.error", {
          submissions,
          sharedSubmissions,
        }).toString();
        this.displaySnackbar(m);
        return;
      }
      // req. met
      else {
        if (this.isLast) this.endDialog = true;
        return this.next();
      }
    }

    // elements that require empty submissions
    if (emptySubmissionElements.has(this.element.type)) {
      this.submitEmpty(this.element);
      this.next();
    }
    // elements that need to be submitted
    else {
      this.loading = true;
      try {
        await this.submit();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }

    // if is last - show dialog
    if (this.isLast) this.endDialog = true;
  }

  toDash() {
    const id = this.seminar?.id;
    if (!id) return this.displaySnackbar("Could not go back to dashboard");
    const route = `/seminars/dashboard/${id}`;
    this.$router.push(route);
  }
}
